import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/dashlite.scss';
import './assets/scss/style-email.scss';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { registerLicense } from '@syncfusion/ej2-base';

const Error404Modern = lazy(() => import('./pages/error/404-modern'));
registerLicense('Mgo+DSMBaFt+QHFqVkNrXVNbdV5dVGpAd0N3RGlcdlR1fUUmHVdTRHRcQl5hQX9Vc0dhWnxfdXY=;Mgo+DSMBPh8sVXJ1S0d+X1RPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9gSX1RfkRlXH1cc3dXT2I=;ORg4AjUWIQA/Gnt2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdE1iX3pccndWQmRe;MTUyNDE1NUAzMjMxMmUzMTJlMzMzNWptYXN4VzFRWEw1Z2VqTHJ4dzlMWFRhNlp1MzlJR2lyaUZ4WVEzUnhVREk9;MTUyNDE1NkAzMjMxMmUzMTJlMzMzNVJoWGNqVEl4YllVMnNDRnV0UHQ3NzJ6Y2RTZVZZT1J3K2JCcGdtaFMwanM9;NRAiBiAaIQQuGjN/V0d+XU9Hc1RDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUdrWHlbcnZWQmFeUw==;MTUyNDE1OEAzMjMxMmUzMTJlMzMzNWFwcThuZU42bUNVSWQrazN2azE3eUE1MnUyRUVxTVprd29ZZ01kMDBaK009;MTUyNDE1OUAzMjMxMmUzMTJlMzMzNUt0UDZ4bGVNRzZYeGJ1djVhajlGNWF1dXcwV1MrYnBseUdxMzJsVVc4UEE9;Mgo+DSMBMAY9C3t2VFhhQlJBfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn5QdE1iX3pccndRTmJe;MTUyNDE2MUAzMjMxMmUzMTJlMzMzNWplVno1OUJKU2ZnOWpBMi92a3BjL0p4anUvd3lBb3JwbFNOVm0zVmFjUmM9;MTUyNDE2MkAzMjMxMmUzMTJlMzMzNVFxR1N4Qm8vZkNMODdoWmtvdHZJc09YWnY4cGFrQmFQM2xBZTVDSkJIdm89;MTUyNDE2M0AzMjMxMmUzMTJlMzMzNWFwcThuZU42bUNVSWQrazN2azE3eUE1MnUyRUVxTVprd29ZZ01kMDBaK009');
// registerLicense('@32302e332e30JUsCwFaqzHvbWeneyoj0SitEKLjHxosEZ5Ctu6HORic=');

ReactDOM.render(
  <React.Fragment>
    <Suspense fallback={<div />}>
      <Router basename={'/'}>
        <Route render={({ location }) => (location.state && location.state ? <Error404Modern /> : <App />)} />
      </Router>
    </Suspense>
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
