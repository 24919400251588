/* eslint-disable no-undef */
import React, { lazy } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { RedirectAs404 } from './utils/Utils';
import PrivateRoute from './route/PrivateRoute';
import ForgotPasswordModule from './true_pages/components/forgot_my_password';

const Layout = lazy(() => import('./layout/Index'));

const Error404Classic = lazy(() => import('./pages/error/404-classic'));
const Error404Modern = lazy(() => import('./pages/error/404-modern'));
const Error504Modern = lazy(() => import('./pages/error/504-modern'));
const Error504Classic = lazy(() => import('./pages/error/504-classic'));

// const Faq = lazy(() => import('./pages/others/Faq'));
// const Terms = lazy(() => import('./pages/others/Terms'));

const Login = lazy(() => import('./pages/auth/Login'));
const Register = lazy(() => import('./pages/auth/Register'));
const ForgotPassword = lazy(() => import('./pages/auth/ForgotPassword'));
const Success = lazy(() => import('./pages/auth/Success'));
const InvoicePrint = lazy(() => import('./pages/pre-built/order-invoice/InvoicePrint'));

const App = () => {
  return (
    <Switch>
      {/* Auth Pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/auth-success`} component={Success}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/auth-reset`} component={ForgotPassword}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/auth-register`} component={Register}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/auth-login`} component={Login}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/password-changer/:id`} component={ForgotPasswordModule}></Route>

      {/* Print Pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/invoice-print/:id`} component={InvoicePrint}></Route>

      {/* Helper pages */}
      {/* <Route exact path={`${process.env.PUBLIC_URL}/auths/terms`} component={Terms}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/auths/faq`} component={Faq}></Route> */}

      <Route exact path={`${process.env.PUBLIC_URL}/invoice-print`} component={InvoicePrint}></Route>

      {/* Error Pages */}
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-classic`} component={Error404Classic}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/504-modern`} component={Error504Modern}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/404-modern`} component={Error404Modern}></Route>
      <Route exact path={`${process.env.PUBLIC_URL}/errors/504-classic`} component={Error504Classic}></Route>

      {/* Main Routes */}
      <PrivateRoute exact path="" component={Layout}></PrivateRoute>
      <Route component={RedirectAs404}></Route>
    </Switch>
  );
};
export default withRouter(App);
