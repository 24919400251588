import React from 'react';
import { Button, Col, Container, Form, Input, InputGroup, Label, Row } from 'reactstrap';
import { useFormik } from 'formik';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { Hasher } from '../../helpers/hasher';
import { changePassword } from '../../providers/user_provider';
import { useParams } from 'react-router';

type SendResetPassword = {
  password: string
  confirmPassword: string
}

const ForgotPassword = () => {
  const { id: token } = useParams<{id: string}>();
  const initialValues: SendResetPassword = { password: '', confirmPassword: '' };
  const validationForm = Yup.object({
    password: Yup.string()
      .required('Se requiere contraseña')
      .min(5, 'Su contraseña es demasiado corta.')
      .matches(/[a-zA-Z]/, 'La contraseña sólo puede contener letras latinas.'),
    confirmPassword: Yup.string()
      .required('Se requiere contraseña')
      .min(5, 'Su contraseña es demasiado corta.')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas deben coincidir')
  });

  // const handleSignout = () => {
  //   localStorage.removeItem('accessToken');
  // };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationForm,
    onSubmit: async (values) => {
      if(values.password !== values.confirmPassword){
        Swal.fire({
          title: 'Las contraseñas no coinciden.',
          icon: 'warning',
        });
      } else {

        const body: { password: string} = {
          password: Hasher.hash.asSHA512(values.password),
        };
        const response = await changePassword(body, token);
        if(response.data.state === true){
          Swal.fire({
            title: 'Contraseña Actualizada satisfactoriamente.',
            text: 'Tu cuenta será cerrada, vuelve a iniciar sesión',
            icon: 'success',
          }).then(() => {
            formik.resetForm();
            // handleSignout();
            // window.location.reload();
          });
        } else {
          Swal.fire({
            title: 'Error inesperado, contacte con soporte',
            icon: 'error',
          }).then(() => {
            formik.resetForm();
          });
        }
      }
    },
  });

  return (
    <Container>
      <Row className='d-flex justify-content-center mb-3'>
        <Form onSubmit={formik.handleSubmit}>
          <Col lg='12' md='12' sm='12' xs='12'>
            <Label className="form-label">Nueva Contraseña</Label>
            <InputGroup className='mb-3'>
              <Input id='password' name='password' type='password' className='form-control-lg form-control' value={formik.values.password} onChange={formik.handleChange} />
              {formik.errors.password && formik.touched.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </InputGroup>
          </Col>
          <Col lg='12' md='12' sm='12' xs='12'>
            <Label className="form-label">Confirmar Contraseña</Label>
            <InputGroup className='mb-3'>
              <Input id='confirmPassword' name='confirmPassword' type='password' className='form-control-lg form-control' value={formik.values.confirmPassword} onChange={formik.handleChange} />
              {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                <div className="error">{formik.errors.confirmPassword}</div>
              ) : null}
            </InputGroup>
          </Col>
          <Col lg='12' md='12' sm='12' xs='12' className='mb-3 d-flex justify-content-center'>
            <Button type='submit'>Agregar</Button>
          </Col>
        </Form>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
