/* eslint-disable @typescript-eslint/no-explicit-any */
import { BACKEND } from '../helpers/common.helper';
import { Hasher } from '../helpers/hasher';

type userLogin = {
  mail: string,
  password: string
}

type userRegister = {
  name: string
  tax_id: string
  mail: string
  password: string
}

export const GetUsers = async () => {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };

  const resp = await fetch(`${BACKEND}/users/get`, requestOptions);

  const sku = await resp.json();
  return sku;
};

export const GetUsersById = async (user_id: string) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };

  const resp = await fetch(`${BACKEND}/users/get_by_id/${user_id}`, requestOptions);

  const sku = await resp.json();
  return sku;
};

export const SignIn = async (body: userLogin) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body)
  };
  const resp = await fetch(`${BACKEND}/users/sign_in`, requestOptions);
  const sku = await resp.json();
  return sku;
};

export const createUser = async (body: userRegister) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body)
  };
  const resp = await fetch(`${BACKEND}/users/create`, requestOptions);
  const sku = await resp.json();
  return sku;
};

export const createSiiCredentials = async (body: any, client_id: string) => {
  const requestOptions: RequestInit = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body)
  };
  const resp = await fetch(`${BACKEND}/client/create/credentials/${client_id}`, requestOptions);
  const sku = await resp.json();
  return sku;
};

export const getSiiCredentials = async (client_id: string) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };
  const resp = await fetch(`${BACKEND}/client/get/credentials/${client_id}`, requestOptions);
  const sku = await resp.json();
  return sku;
};

export const existSiiCredentials = async (client_id: string) => {
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  };
  const resp = await fetch(`${BACKEND}/client/exist/credentials/${client_id}`, requestOptions);
  const sku = await resp.json();
  return sku;
};

export const patchRole = async (body: any) => {
  const requestOptions: RequestInit = {
    method: 'PATCH',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body)
  };
  const resp = await fetch(`${BACKEND}/users/update_role`, requestOptions);
  const sku = await resp.json();
  return sku;
};

export const changePassword = async (body: {password: string}, id_Cliente: string) => {
  const requestOptions: RequestInit = {
    method: 'PATCH',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    body: JSON.stringify(body)
  };
  const resp = await fetch(`${BACKEND}/users/update_password/${id_Cliente}`, requestOptions);
  const sku = await resp.json();
  return sku;
};

export const getMail = async () => {
  const user = localStorage.getItem('accessToken');
  const data = JSON.parse(Hasher.decode.Base64(user?? ''));
  const body = {
    'id': data.id,
    'mail': data.mail,
    'userName': data.name,
    'role': data.roles
  };
  return body;
};