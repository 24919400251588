import React, { Fragment } from 'react';
import Head from '../../layout/head/Head';
import PageContainer from '../../layout/page-container/PageContainer';
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, PreviewCard } from '../../components/Component';
import Logo from '../../layout/logo/Logo';
import ForgotPassword from '../forms/forgot_password';

const ForgotPasswordModule = () => {
  return (
    <Fragment>
      <div style={{ backgroundColor: '#ffffff' }}>
        <Head title='Olvide mi contraseña' />
        <PageContainer>
          <Block className='nk-block-middle nk-auth-body  wide-xs' size={undefined}>
            <div className='brand-logo pb-4 text-center'>
              <Logo/>
            </div>
            <PreviewCard className='card-bordered' bodyClass='card-inner-lg'>
              <BlockHead className={undefined} size={undefined} wide={undefined}>
                <BlockContent className={undefined}>
                  <BlockTitle tag='h4' className={undefined} page={undefined}>Olvide mi contraseña</BlockTitle>
                  <BlockDes className={undefined}>
                    <p>Ingresa tu nueva contraseña</p>
                  </BlockDes>
                </BlockContent>
                <BlockContent className={undefined}>
                  <ForgotPassword />
                </BlockContent>
              </BlockHead>
            </PreviewCard>
          </Block>
          {/* <AuthFooter /> */}
        </PageContainer>
      </div>
    </Fragment>
  );
};

export default ForgotPasswordModule;
